<template>
  <ValidationObserver ref="showForm" v-slot="{ handleSubmit }">
    <div class="flex justify-between mb-10">
      <h4 class="text-3xl sm:text-5xl font-bold w-2/3 leading-tight">
        We want to get to know you better
      </h4>
      <!-- Step counter -->
      <StepsCounter v-if="steps.length" :stepCount="steps.length" :currentStep="currentStep"/>
    </div>

    <div class="mt-6 sm:mt-10 text-gray-500 font-light text-xl">
      Let’s fill out the rest of your information. This helps the guest make a decision about your booking.
    </div>
    <form method="post" @submit.prevent="handleSubmit(saveShow)">
      <div class="mt-12 grid grid-cols-1 row-gap-6">
        <div>
          <label class="font-medium text-black">Name of your Show</label>
          <ValidationProvider rules="required" name="Name" vid="name" v-slot="{ errors }">
            <div class="mt-2">
              <input type="text" class="form-input text-black text-lg bg-gray-50 w-full px-5 py-3 rounded-full border-transparent focus:bg-white focus:shadow-3xl" v-model="showForm.name">
            </div>
            <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div>
          <label class="font-medium text-black">URL to Website</label>
          <ValidationProvider rules="required" name="URL" vid="url" v-slot="{ errors }">
            <div class="mt-2">
              <input type="text" class="form-input text-black text-lg bg-gray-50 w-full px-5 py-3 rounded-full border-transparent focus:bg-white focus:shadow-3xl" v-model="showForm.url">
            </div>
            <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div>
          <label class="font-medium text-black">Description</label>
          <ValidationProvider name="Description" vid="description" v-slot="{ errors }">
            <div class="mt-2">
              <textarea v-model="showForm.description"
                      class="w-full bg-gray-50 rounded-xl resize-none p-5 text-black text-lg focus:border focus:border-pink-500 focus:shadow-3xl focus:bg-white"
                      :class="{'border-red-500': errors.length}"/>
            </div>
            <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div>
          <label class="font-medium text-black">Tags</label>
          <ValidationProvider name="Tags" vid="tags" v-slot="{ errors }">
            <div class="mt-2">
              <multiselect class="new-multiselect" v-model="showForm.tags" tag-placeholder="Add this as new tag"
                        placeholder="Search or add a tag" label="name"
                        track-by="value" :options="tags" :multiple="true" :taggable="true"
                        @tag="addTag"></multiselect>
            </div>
            <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div v-if="categories.length">
          <label class="font-medium text-black">Categories</label>
          <ValidationProvider rules="required" name="Categories" vid="categories" v-slot="{ errors }">
            <multiselect
              v-model="showForm.categories"
              placeholder="Pick at least one category"
              label="name"
              track-by="name"
              :options="categories"
              :multiple="true"
              :max="2"
              class="new-multiselect"
            >
              <template slot="tag" slot-scope="{ option, remove }">
                <span class="multiselect__tag">
                  <span v-if="option.parent_id" class="font-light">{{option.parent_name}}: </span><span class="font-bold">{{option.name}}</span>
                  <i class="multiselect__tag-icon" @click="remove(option)"></i>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="text-xs"><span v-if="props.option.parent_id">- </span><span class="" :class="{'font-semibold': props.option.parent_id == null}">{{ props.option.name }}</span></div>
              </template>
            </multiselect>
            <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div>
          <label class="font-medium text-black">Show Image</label>
          <ValidationProvider name="Image" vid="imageUrl" v-slot="{ errors }">
            <image-upload v-model="showForm.imageUrl" />
            <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
      </div>
      <div class="mt-12 flex justify-center">
        <button type="submit" class="rounded-full h-16 sm:h-20 bg-indigo-gradiant text-white text-xl font-bold flex justify-center items-center uppercase px-16 disabled:opacity-50" :disabled="loading">
          <span v-if="loading">
            <loading-icon class="h-5 w-5"/>
          </span>
          <span v-else>Continue</span>
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import api from '@/api'
  import StepsCounter from '@/components/StepsCounter'
  import Multiselect from 'vue-multiselect'
  import ImageUpload from '@/components/shared/ImageUpload'

  export default {
    name: "ShowForm",

    components: { StepsCounter, Multiselect, ImageUpload },

    data() {
      return {
        loading: false,
        tags: [],
        categories: [],
        showForm: {
          tags: [],
          categories: [],
        }
      }
    },

    watch: {
      shows(shows) {
        this.showForm = shows[0]
      },
    },

    computed: {
      show() {
        return this.$store.getters['guestBooking/show']
      },

      steps() {
        return this.$store.getters['guestBooking/enabledSteps']
      },

      currentStep() {
        return this.$store.state.guestBooking.currentStep
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      shows() {
        return this.user ? this.user.shows : []
      },
    },

    methods: {
      async saveShow() {
        this.loading = true

        try {
          let categoriesIds = this.showForm.categories.map(c => c.id);

          if (this.showForm.id) {
            await this.$store.dispatch('shows/updateShow', {
              ...this.showForm,
              categories: categoriesIds,
            })
            this.setShow(this.showForm)
            this.nextStep()
            return
          }

          await this.$store.dispatch('shows/create', {
            ...this.showForm,
            categories: categoriesIds,
          })
          this.setShow(this.$store.state.shows.show)

          // refresh user
          await this.$store.dispatch('auth/getUser')

          this.nextStep()
        } catch(error) {
          this.$refs.showForm.setErrors(error.response.data.errors)
          this.loading = false
        }
      },

      setShow(show) {
        this.$store.commit('guestBooking/setShow', show)
      },

      nextStep() {
        let nextStepRoute = this.steps[this.currentStep].route

        this.$router.push({name: nextStepRoute})
      },

      addTag(newTag) {
        const tag = {
          name: newTag,
          value: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
        }
        this.showForm.tags.push(tag)
        this.tags.push(tag)
      },
    },

    created() {
      api.get(`/tags`).then(({ data }) => {
        for (let key in data.data) {
          this.tags.push({name: data.data[key], value: key })
        }
      })

      api.get(`/categories?exclude=subcategories`).then(({ data }) => {
        if (data.success) {
          this.categories = data.data
        }
      })
    },

    mounted() {
      if (this.shows && this.shows.length) {
        this.showForm = this.shows[0]
      }

      window.Event.$on('showFormErrors', errors => {
        this.$refs.showForm.setErrors(errors)
      })
    },
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .new-multiselect {
    background-color: '#f5f5f5' !important;
  }
  .new-multiselect .multiselect__select {
    padding: 16px 8px 4px 8px;
  }
  .new-multiselect .multiselect__tags {
    /*border-radius: 999px;*/
    padding: 13px 40px 0 8px;
  }
</style>
