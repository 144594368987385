<template>
  <div>
    <div class="flex justify-between">
      <h4 class="text-3xl sm:text-5xl font-bold w-2/3 leading-tight mt-4">
        Select your Show
      </h4>
      <!-- Step counter -->
      <StepsCounter v-if="steps.length" :stepCount="steps.length" :currentStep="currentStep"/>
    </div>
    <div class="mt-6 sm:mt-10 text-gray-500 font-light text-xl">
      What Show are you booking the guest on?
    </div>
    <div class="mt-12 grid grid-cols-2 gap-8">
      <div v-for="show in shows" :key="`show-${show.id}`" class="relative pb-full">
        <label
          class="absolute inset-0 p-4 rounded-xl border border-gray-300 bg-white shadow-xl cursor-pointer hover:border-pink-400"
          :class="{'border-pink-500 hover:border-pink-500 bg-pink-100': selectedShowId == show.id}"
        >
          <div class="relative rounded-xl overflow-hidden w-full h-full">
            <template v-if="show.imageUrl">
              <img :src="show.imageUrl" class="w-full h-full object-cover" alt="">
            </template>
            <template v-else>
              <img src="/img/test_image.01d18838.png" class="w-full h-full object-cover" alt="">
              <span class="absolute inset-x-0 bottom-0 py-1 flex items-center justify-center bg-black bg-opacity-75 text-gray-200">
                {{ show.name }}
              </span>
            </template>
          </div>
          <input type="radio" class="hidden" name="show" :value="show.id" v-model="selectedShowId">
        </label>
      </div>
    </div>
    <div class="mt-12 flex justify-center">
      <button @click="nextStep" class="rounded-full h-16 sm:h-20 bg-indigo-gradiant text-white text-xl font-bold flex justify-center items-center uppercase px-16 disabled:opacity-50"
        :disabled="loading || ! selectedShowId"
      >
        <span v-if="loading">
          <loading-icon class="h-5 w-5"/>
        </span>
        <span v-else>Continue</span>
      </button>
    </div>
  </div>
</template>

<script>
  import StepsCounter from '@/components/StepsCounter'
  export default {
    components: {
      StepsCounter,
    },

    data() {
      return {
        loading: false,
        selectedShowId: null,
      }
    },

    watch: {
      selectedShowId(id) {
        let show = this.shows.find(p => p.id == id)

        if (show) {
          this.$store.commit('guestBooking/setShow', show)
        }
      }
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      },

      shows() {
        return this.user ? this.user.shows : []
      },

      show() {
        return this.$store.getters['guestBooking/show']
      },

      steps() {
        return this.$store.getters['guestBooking/enabledSteps']
      },

      currentStep() {
        return this.$store.state.guestBooking.currentStep
      },
    },

    methods: {
      nextStep() {
        this.loading = true

        let nextStepRoute = this.steps[this.currentStep].route

        this.$router.push({name: nextStepRoute})
      },
    },

    mounted() {
      if (this.show) {
        this.selectedShowId = this.show.id
      }
    }
  }
</script>
