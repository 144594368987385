<template>
    <div>
      <GuestBookingShowStep />
    </div>
</template>

<script>
  import GuestBookingShowStep from "@/components/Book/guest/GuestBookingShowStep"

  export default {
    components: {
      GuestBookingShowStep,
    },

    computed: {
      steps() {
        return this.$store.getters['guestBooking/enabledSteps']
      },
    },

    methods: {
      determineStepNumber() {
        let step = this.steps.findIndex(step => step.name == 'profile')

        if (step === -1) {
          return this.$router.push({ name: this.steps[0].route })
        }

        this.$store.commit('guestBooking/setCurrentStep', step + 1)
      }
    },

    mounted() {
      this.determineStepNumber()
    }
  }
</script>
