<template>
  <div>
    <div v-if="image" class="preview">
      <div class="relative h-40 w-40 z-0" v-if="image && ! uploading">
        <button type="button" @click.prevent="removeMedia" class="absolute z-10 top-0 right-0 -mr-3 -mt-3 bg-white rounded-full text-red-300 hover:text-red-500">
          <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>
        </button>
        <div class="w-full h-full overflow-hidden">
          <img :src="image" class="cursor-pointer w-full h-full object-cover rounded-md">
        </div>
      </div>
    </div>
    <div v-show="! image" class="form-input border-dashed border-2 border-gray-300 h-40 flex flex-col items-center justify-center relative">
      <ImagePlusIcon class="group-hover:text-indigo-500 transition-all duration-300 w-12 h-12"/>

      <div v-show="! uploading" class="flex flex-col items-center mt-4">
        <label class="cursor-pointer text-indigo-500 font-semibold hover:text-indigo-600">
          <input ref="upload" type="file" class="hidden" @change="uploadImage">
          <span class="absolute inset-0"></span>
        </label>
        <span class="mt-2 text-sm text-gray-500">
          PNG, JPG, GIF up to 20MB
        </span>
      </div>
      <div v-if="uploading" class="text-sm text-gray-700 mt-2">Uploading...</div>
      <div v-if="! uploading && uploadingError" class="text-sm text-red-500 mt-2">The image could not be uploaded</div>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import ImagePlusIcon from '@/components/svgs/ImagePlusIcon';

  export default {
    props: {
      value: String
    },

    components: {
      ImagePlusIcon
    },

    data() {
      return {
        uploading: false,
        uploadingError: false,
        image: this.value,
        file: null,
      }
    },

    watch: {
      value(newValue) {
        this.image = newValue
      },

      image(newImage) {
        this.$emit('input', newImage)

        if (newImage) {
          this.$emit('media', {
            url: newImage,
            file_name: this.file.name,
            size: this.file.size,
          })
        }
      }
    },

    methods: {
      uploadImage() {
        if (this.$refs.upload.files.length == 0 ) {
          return
        }

        this.file = this.$refs.upload.files[0];

        this.uploading = true
        this.uploadingError = false

        let uploadForm = new FormData()

        uploadForm.append('image', this.file)

        api.post('/uploads', uploadForm)
          .then(response => {
            this.image = response.data.uploads[0];
          })
          .catch(() => {
              this.uploadingError = true
          })
          .finally(() => {
              this.uploading = false
              this.$refs.upload.value = null
          })
      },

      removeMedia() {
        this.image = null
        this.file = null
        this.$emit('input', null)
      },
    },
  }
</script>
