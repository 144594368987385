<template>
  <div class="min-h-full flex flex-col">
    <template v-if="shows && shows.length >= 2">
      <ShowPicker />
    </template>

    <template v-else>
      <ShowForm />
    </template>

    <div v-if="currentStep == 2" class="text-center mt-4">
      <button @click.prevent="prev" type="button" class="text-sm text-pink-500 hover:underline">Previous step</button>
    </div>
  </div>
</template>

<script>
  import ShowForm from '@/components/Book/guest/ShowForm'
  import ShowPicker from '@/components/Book/guest/ShowPicker'
  export default {
    components: {
      ShowForm,
      ShowPicker,
    },

    data() {
      return {
        loading: false,
      }
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      },

      shows() {
        return this.user ? this.user.shows : []
      },

      code() {
        return this.$store.getters['guestBooking/code']
      },

      queryCode() {
        return this.$route.query.code
      },

      currentStep() {
        return this.$store.state.guestBooking.currentStep
      },

      bookingType() {
        return this.$store.getters['guestBooking/type']
      },
    },

    watch: {
      // user(user) {
      //   // console.log(user)
      // }
    },

    methods: {
      saveShow() {
        alert('save the show')
        this.next()
      },

      next() {
        this.loading = true

        setTimeout(() => {
          this.$store.commit('guestBooking/nextStep')
          this.$router.push({hash: 'step-3'})
        }, 1200)
      },

      prev() {
        this.$store.commit('guestBooking/prevStep')
        this.$router.push({hash: ''})
      }
    },

    mounted() {
      if (this.bookingType == 'normal' && ! this.$store.getters['guestBooking/selectedSlots'].length) {
        this.prev()
      }

      window.scrollTo(0, 0)
    }
  }
</script>
